@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

@media screen and (prefers-color-scheme: dark) {
  :root {
    --primary-color: #ffffff;
    --bg-color: #232323;
    --Red: #8b0304;
    --Grey: #808285;
    --Light-Gray: #d1d3d4;
    --Dark: #414042;
  }
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --primary-color: #1d2343;
    --bg-color: #ededee;
    --Red: #8b0304;
    --Grey: #808285;
    --Light-Gray: #d1d3d4;
    --Dark: #414042;
  }
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
.arabic {
  direction: rtl;
  font-family: "Almarai", sans-serif;
}
.arabicFont {
  font-family: "Almarai", sans-serif;
}
body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Almarai", sans-serif;
  color: var(--primary-color);
  line-height: 1.2;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.5s, background-color 0.5s;
  font-weight: 500;
}
div#root {
  border-top: 10px solid #ec1c24;
}
main {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Almarai", sans-serif;
  line-height: 1.2;
  font-weight: 500;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-color);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

a,
button,
input,
textarea {
  font-family: inherit;
}
a,
button {
  cursor: pointer;
  line-height: 1;
}
*:focus {
  outline: 0 !important;
}
img,
video,
iframe {
  max-inline-size: 100%;
  block-size: auto;
}
ul {
  margin: 0;
  padding: 0;
  list-style: inside;
}
.max-width {
  max-width: 1366px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1180px) {
}

@media only screen and (max-width: 867px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 441px) {
}

@media screen and (prefers-color-scheme: dark) {
}

@media screen and (prefers-color-scheme: light) {
}

@media (horizontal-viewport-segments: 2) and (vertical-viewport-segments: 1) {
}

@media (vertical-viewport-segments: 2) and (horizontal-viewport-segments: 1) {
}
